const links = document.querySelectorAll('a:not([href^="#"])')
const queries = window.location.search.replace('?', '')

links.forEach((link) => {
  link.addEventListener('click', (event) => {
    event.preventDefault()

    if (event.currentTarget.href) {
      if (event.currentTarget.target === '_blank') {
        window.open(
          `${event.currentTarget.href}?${queries}&partner=pb`,
          '_blank'
        )
      } else {
        window.location.href = `${event.currentTarget.href}?${queries}&partner=pb`
      }
    }
  })
})