import anime from 'animejs'
import { Howl, Howler } from 'howler'

window.addEventListener('load', () => {
  const sound1 = new Howl({
    src: ['https://origin.pb06e2-resources.com/webStaticPB/promo/bestsup/1629.mp3'],
    volume: 0.6,
  })
  const sound2 = new Howl({
    src: ['https://origin.pb06e2-resources.com/webStaticPB/promo/bestsup/1689.mp3'],
    volume: 0.6,
  })
  const sound3 = new Howl({
    src: ['https://origin.pb06e2-resources.com/webStaticPB/promo/bestsup/1684.mp3'],
    volume: 0.6,
  })

  const audios = [sound1, sound2, sound3]

  const playBtns = document.querySelectorAll('.player')

  playBtns.forEach((btn) => {
    const idx = btn.dataset.audio
    btn.addEventListener('click', () => {
      audios.every((audio) => audio.stop())
      dot?.remove()
      timeline?.remove()
      if (btn.classList.contains('player--playing')) {
        playBtns.forEach((btn) => btn.classList.remove('player--playing'))
      } else {
        playBtns.forEach((btn) => btn.classList.remove('player--playing'))
        audios[idx].play()
        setTimeout(() => {
          btn.classList.add('player--playing')
        }, 200)
      }
    })
  })

  audios.forEach((audio) => {
    audio.on('end', () => {
      audio.stop()
      playBtns.forEach((btn) => btn.classList.remove('player--playing'))
    })
    audio.on('load', () => {
      playBtns.forEach((btn) => btn.classList.remove('player--loading'))
    })
    audio.on('play', () => {
      progressLine(audio)
    })
  })

  let timeline, dot

  function progressLine(audio) {
    timeline = anime({
      targets: '.player .timeline',
      strokeDashoffset: [anime.setDashoffset, 0],
      easing: 'linear',
      duration: audio.duration() * 1000,
      loop: false,
    })
    const path = anime.path('.player .timeline')
    dot = anime({
      targets: '.player .player__dot',
      translateX: path('x'),
      translateY: path('y'),
      duration: audio.duration() * 1000,
      easing: 'linear',
    })
  }
})
