import Swiper, { Navigation, Autoplay } from 'swiper'

const slider = new Swiper('.winners-slider .swiper', {
  modules: [Navigation, Autoplay],
  spaceBetween: 100,
  rewind: true,
  autoplay: {
    delay: 5000,
    pauseOnMouseEnter: true,
  },
  breakpoints: {
    320: {
      autoHeight: true,
    },
    1000: {
      autoHeight: false,
    },
  },
  navigation: {
    prevEl: '.winners-slider__prev',
    nextEl: '.winners-slider__next',
  },
  on: {
    slideChangeTransitionEnd: changeActiveTrack,
    touchMove: function (swiper) {
      swiper.autoplay.stop()
    },
  },
})

const track = document.querySelectorAll('.winners-slide__track')

track.forEach((item, idx) => {
  item.addEventListener('click', () => {
    slider.slideTo(idx)
    track.forEach((item) => {
      item.classList.remove('winners-slide__track--active')
    })
    item.classList.add('winners-slide__track--active')
  })
})

function changeActiveTrack(swiper) {
  track.forEach((item) => {
    item.classList.remove('winners-slide__track--active')
  })
  track[swiper.activeIndex].classList.add('winners-slide__track--active')
  trackSlider.slideTo(swiper.activeIndex)
}

const trackSlider = new Swiper('.winners-slider__pagination .swiper', {
  modules: [Navigation],
  breakpoints: {
    320: {
      slidesPerView: 3,
    },
    1000: {
      slidesPerView: 6,
    },
  },
  navigation: {
    prevEl: '.winners-slider__pagination-prev',
    nextEl: '.winners-slider__pagination-next'
  }
})
