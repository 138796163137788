import './modules/utm'
import './modules/smart-script'
import './modules/scroll'
import './modules/player'
import './modules/phone-slider'
import './modules/winners-slider'
// import './modules/popup'
import './modules/animation'

import smoothscroll from 'smoothscroll-polyfill'

smoothscroll.polyfill()

let isMobile = false

const MOBILE_WIDTH = 1000

const setIsMobile = () =>
  window.innerWidth <= MOBILE_WIDTH ? (isMobile = true) : (isMobile = false)

window.addEventListener('resize', () => {
  setIsMobile()
})

setIsMobile()

const phoneBtn = document.querySelectorAll('.phone__btn')
const phoneDisplay = document.querySelector('.phone__wrapper')
const phoneDisplayMob = document.querySelector('.phone--mob .phone__wrapper')

let counter = 0
let display = []

phoneBtn.forEach((btn) => {
  btn.addEventListener('click', (evt) => {
    counter += 1

    display.push(counter)

    if (!isMobile) {
      phoneDisplay.innerHTML = `<div class="phone__display">${display
        .map(
          (i) => `
                            <div class="phone__chat-inner">
                                <div class="phone__client phone__client--1">
<!--                                    <img src="images/phone/client.png">-->
                                </div>
                                <div class="phone__chat phone__chat--1 phone__chat--left"></div>
                            </div>

                            <div class="phone__chat-inner">
                                <div class="phone__chat phone__chat--2 phone__chat--right"></div>
                                <div class="phone__image phone__image-operator phone__image-operator--1"></div>
                            </div>

                            <div class="phone__chat-inner">
                                <div class="phone__client phone__client--2">
<!--                                    <img src="images/phone/client.png">-->
                                </div>
                                <div class="phone__chat phone__chat--3 phone__chat--left"></div>
                            </div>

                            <div class="phone__chat-inner">
                                <div class="phone__chat phone__chat--4 phone__chat--right"></div>
                                <div class="phone__image phone__image-operator phone__image-operator--2"></div>
                            </div>

                            <div class="phone__chat-inner">
                                <div class="phone__client phone__client--3">
<!--                                    <img src="images/phone/client.png">-->
                                </div>
                                <div class="phone__chat phone__chat--5 phone__chat--left"></div>
                            </div>

                            <div class="phone__chat-inner">
                                <div class="phone__chat--right">
                                    <div class="phone__chat phone__chat--6"></div>
                                    <div class="phone__chat phone__chat--7"></div>
                                </div>
                                <div class="phone__image phone__image-operator phone__image-operator--3"></div>
                            </div>

                            <div class="phone__chat-inner">
                                <div class="phone__client phone__client--4">
<!--                                    <img src="images/phone/client.png">-->
                                </div>
                                <div class="phone__chat phone__chat--8 phone__chat--left"></div>
                            </div>

                            <div class="phone__chat-inner">
                                <div class="phone__chat phone__chat--9 phone__chat--right"></div>
                                <div class="phone__image phone__image-operator phone__image-operator--4"></div>
                            </div>
                        `
        )
        .join(' ')} </div>`

      phoneDisplay.scrollBy({
        top: 240,
        left: 0,
        behavior: 'smooth',
      })
    }

    if (isMobile) {
      phoneDisplayMob.innerHTML = `<div class="phone__display">${display
        .map(
          (i) => `
                            <div class="phone__chat-inner">
                                <div class="phone__client phone__client--1">
<!--                                    <img src="images/phone/client.png">-->
                                </div>
                                <div class="phone__chat phone__chat--1 phone__chat--left"></div>
                            </div>

                            <div class="phone__chat-inner">
                                <div class="phone__chat phone__chat--2 phone__chat--right"></div>
                                <div class="phone__image phone__image-operator phone__image-operator--1"></div>
                            </div>

                            <div class="phone__chat-inner">
                                <div class="phone__client phone__client--2">
<!--                                    <img src="images/phone/client.png">-->
                                </div>
                                <div class="phone__chat phone__chat--3 phone__chat--left"></div>
                            </div>

                            <div class="phone__chat-inner">
                                <div class="phone__chat phone__chat--4 phone__chat--right"></div>
                                <div class="phone__image phone__image-operator phone__image-operator--2"></div>
                            </div>

                            <div class="phone__chat-inner">
                                <div class="phone__client phone__client--3">
<!--                                    <img src="images/phone/client.png">-->
                                </div>
                                <div class="phone__chat phone__chat--5 phone__chat--left"></div>
                            </div>

                            <div class="phone__chat-inner">
                                <div class="phone__chat--right">
                                    <div class="phone__chat phone__chat--6"></div>
                                    <div class="phone__chat phone__chat--7"></div>
                                </div>
                                <div class="phone__image phone__image-operator phone__image-operator--3"></div>
                            </div>

                            <div class="phone__chat-inner">
                                <div class="phone__client phone__client--4">
<!--                                    <img src="images/phone/client.png">-->
                                </div>
                                <div class="phone__chat phone__chat--8 phone__chat--left"></div>
                            </div>

                            <div class="phone__chat-inner">
                                <div class="phone__chat phone__chat--9 phone__chat--right"></div>
                                <div class="phone__image phone__image-operator phone__image-operator--4"></div>
                            </div>
                        `
        )
        .join(' ')} </div>`

      phoneDisplayMob.scrollBy({
        top: 240,
        left: 0,
        behavior: 'smooth',
      })
    }
  })
})

const closeButton = document.getElementById('uw-main-button-close')

window.addEventListener('load', async () => {
  if (open === undefined) {
    usedeskMessenger.open()
  }
  usedeskMessenger.open()
})
