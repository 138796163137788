import Swiper, { Navigation, Autoplay } from 'swiper'

const phone = new Swiper('.phone-slider .swiper', {
  modules: [Navigation, Autoplay],
  autoplay: {
    delay: 4000,
    pauseOnMouseEnter: true
  },
  spaceBetween: 100,
  rewind: true,
  navigation: {
    prevEl: '.phone-slider__navigation .phone-slider__prev',
    nextEl: '.phone-slider__navigation .phone-slider__next',
  },
  on:{
    touchMove: function(swiper){
      swiper.autoplay.stop()
    }
  }
})
