import anime from 'animejs'

const mainTL = anime.timeline({easing: 'easeOutExpo', duration: 750})
let isLoad = false

window.addEventListener('load', animation)
document.addEventListener('DOMContentLoaded', () => {
    setTimeout(() => {
        animation()
    }, 8000)
})

function animation() {
    if (!isLoad) {
        mainTL
            .add(
                {
                    targets: '.logo',
                    opacity: [0, 1],
                }, 150
            )
            .add(
                {
                    targets: '.header__buttons',
                    opacity: [0, 1],
                    delay: anime.stagger(100),
                },
                200
            )
            .add(
                {
                    targets: '.promo',
                    opacity: [0, 1],
                    translateY: [100, 0],
                },
                1000
            )
            .add(
                {
                    targets: '.phone',
                    opacity: [0, 1],
                    translateX: [-150, 0],
                }, 1200
            )
            .add(
                {
                    targets: '.promo__item',
                    opacity: [0, 1],
                    translateX: [150, 0],
                    delay: anime.stagger(100),
                },
                1200
            )
            .add(
                {
                    targets: '.results',
                    opacity: [0, 1],
                    translateY: [100, 0],
                },
                1300
            )
            .add(
                {
                    targets: '.footer',
                    opacity: [0, 1],
                },
                1000
            )
            .add(
                {
                    targets: '.payments img',
                    opacity: [0, 1],
                    translateX: [-50, 0],
                    delay: anime.stagger(50),
                },
                1000
            )
            .add(
                {
                    targets: '.app',
                    opacity: [0, 1],
                    translateX: [-150, 0],
                    delay: anime.stagger(50),
                },
                1000
            )
            .add(
                {
                    targets: '.soc > div, .soc a',
                    opacity: [0, 1],
                    translateX: [150, 0],
                    delay: anime.stagger(50),
                },
                1000
            )
    }
    isLoad = true
}

function chatAnimation() {
    mainTL
        .add({
            delay: 500,
        })
        .add({
            targets: '.phone__client--1',
            opacity: [0, 1],
        },1000)
        .add({
            targets: '.phone__chat--1',
            opacity: [0, 1],
            translateY: [100, 0]
        }, 1200)
        .add({
            targets: '.phone__image-operator--1',
            opacity: [0, 1],
            translateY: [100, 0]
        }, 1300)
        .add({
            targets: '.phone__chat--2',
            opacity: [0, 1],
            translateY: [100, 0]
        }, 1400)
        .add({
            targets: '.phone__client--2',
            opacity: [0, 1],
            translateY: [100, 0]
        }, 1500)
        .add({
            targets: '.phone__chat--3',
            opacity: [0, 1],
            translateY: [100, 0]
        }, 1600)
        .add({
            targets: '.phone__image-operator--2',
            opacity: [0, 1],
            translateY: [100, 0]
        }, 1700)
        .add({
            targets: '.phone__chat--4',
            opacity: [0, 1],
            translateY: [100, 0]
        }, 1800)
        .add({
            targets: '.phone__client--3',
            opacity: [0, 1],
            translateY: [100, 0]
        }, 1900)
        .add({
            targets: '.phone__chat--5',
            opacity: [0, 1],
            translateY: [100, 0]
        }, 2000)
        .add({
            targets: '.phone__chat--6',
            opacity: [0, 1],
            translateY: [100, 0]
        }, 2100)
}

chatAnimation()


